import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  name: 'edictos_main',
  path: '/',
  component: () => import('@/shared/layouts/web.layout.vue'),
  children: [
    {
      path: '',
      name: 'edictos_page',
      meta: { title: 'Edictos', label: 'Edictos' },
      component: () => import('../pages/EdictosPage.vue'),
    },
  ],
}

export default routes;
