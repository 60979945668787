import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  name: 'perdida_dominio_main',
  path: '/perdida_dominio',
  component: () => import('@/shared/layouts/web.layout.vue'),
  children: [
    {
      path: '',
      name: 'perdida_dominio_page',
      meta: { title: 'Perdida de Dominio', label: 'Perdida de Dominio' },
      component: () => import('../pages/PerdidaDominioPage.vue'),
    },
  ],
}

export default routes;
