import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  name: 'persona_desaparecida_main',
  path: '/personas_desaparecidas',
  component: () => import('@/shared/layouts/web.layout.vue'),
  children: [
    {
      path: '',
      name: 'persona_desaparecida_page',
      meta: { title: 'Persona Desaparecida', label: 'Persona Desaparecidas' },
      component: () => import('../pages/PersonaDesaparecidaPage.vue'),
    },
  ],
}

export default routes;
