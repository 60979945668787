export const INSTITUCION_NAME = import.meta.env.VITE_INSTITUCION || 'MINISTERIO PÚBLICO';
export const PROYECT_NAME = import.meta.env.VITE_APLICACION_NAME || 'Ecosistema JL2';
export const DATATABLE_TEXT_EMPTY = import.meta.env.VITE_DATATABLE_EMPTY_TEXT || 'SIN REGISTRO';

export const PROYECT_VERSION = import.meta.env.VITE_VERSION || '0.0.1';

export const FRONT_PORT = import.meta.env.VITE_PORT || 8080;

export const APP_ID = import.meta.env.VITE_APP_ID || 0;

export const JL_API_URL = import.meta.env.VITE_JL_API;
export const ENCRYPT_KEY = import.meta.env.VITE_ENCRYPT_KEY;
export const RRHH_URL_PHOTO = import.meta.env.VITE_RRHH_API || 'https://rrhh-api-test.fiscalia.gob.bo';
