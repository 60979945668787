import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  name: 'persona_rebeldia_main',
  path: '/personas_rebeldia',
  component: () => import('@/shared/layouts/web.layout.vue'),
  children: [
    {
      path: '',
      name: 'persona_rebeldia_page',
      meta: { title: 'Persona Rebeldía', label: 'Persona Rebeldías' },
      component: () => import('../pages/PersonaRebeldiaPage.vue'),
    },
  ],
}

export default routes;
