import type { RouteRecordRaw } from 'vue-router';

const router: RouteRecordRaw = {
  name: 'error',
  path: '/',
  component: () => import('@/shared/layouts/empty.layout.vue'),
  children: [
    {
      path: '/access-denegado',
      name: 'not-access',
      meta: { title: 'Acceso denegado' },
      component: () => import('../views/not-access.view.vue'),
    },
    {
      path: '/error',
      name: 'error',
      meta: { title: 'Error' },
      component: () => import('../views/error.view.vue'),
    },
    {
      path: '/404',
      name: 'not-found',
      meta: { title: '404 - No encontrado' },
      component: () => import('../views/not-found.view.vue'),
    },
  ],
};

export default router;
