import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw = {
  name: 'some_path',
  path: '/example',
  component: () => import('@/shared/layouts/theme.layout.vue'),
  redirect: { name: 'dashboard' },
  children: [
    {
      path: 'dashboard',
      name: 'dashboard_page',
      meta: { title: 'Dashboard' },
      component: () => import('../views/dashboard.view.vue'),
    },
  ],
};

export default routes;
