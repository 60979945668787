import { createApp } from 'vue'
import { Settings } from 'luxon';
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import router from './router';
import { createPinia } from 'pinia';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import { VueQueryPlugin } from '@tanstack/vue-query';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './App.scss';
import './fonts.scss';

import App from './App.vue'
import { PRIME_LOCALE_ES } from './constants/locale.const';

const app = createApp(App);

export const appState = reactive({
    colorScheme: 'light',
    isNewThemeLoaded: false,
});

Settings.defaultLocale = 'es';
app.use(createPinia());
app.use(router);
app.use(PrimeVue, {
    ripple: true,
    locale: PRIME_LOCALE_ES,
});
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueQueryPlugin);

app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Toast', Toast);
app.directive('tooltip', Tooltip);

app.mount('#app');
