import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import { INSTITUCION_NAME } from '@/constants/env.const';
import { useAppStore } from '@/store/app.store';
import exampleRoutes from '@/modules/exampleModule/router';
import sharedRoutes from '@/modules/errorModule/router';
import personaDesaparecidaRoutes from '@/modules/personas_desaparecidas/router';
import PerdidaDominioRoutes from '@/modules/perdidas_dominios/router';
import EdictosRoutes from '@/modules/edictos/router';
import personaRebeldiaRoutes from '@/modules/personas_rebeldia/router';
import datasetRoutes from '@/modules/datasets/router';

const routes: RouteRecordRaw[] = [
  { ...exampleRoutes },
  { ...sharedRoutes },
  { ...personaDesaparecidaRoutes },
  { ...personaRebeldiaRoutes },
  { ...PerdidaDominioRoutes },
  { ...EdictosRoutes },
  { ...datasetRoutes },
  {
    path: '',
    redirect: {
      name: 'edictos_page',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
});

router.beforeEach((to) => {
  const appStore = useAppStore();
  appStore.isLoading = true;
  try {
    if (to.meta) document.title = to.meta?.title || INSTITUCION_NAME;
    else document.title = INSTITUCION_NAME;
  } catch (e) {
    appStore.isLoading = false;
    // console.log(e);
  }
});

router.beforeResolve(() => {
  const appStore = useAppStore();
  appStore.isLoading = false;
});

export default router;
